<template>
    <VForm
        v-slot="{ errors }"
        @submit="onSubmit">
        <div class="flex flex-col gap-6">
            <BaseInput
                v-model="model.email"
                :label="$t('auth.email')"
                rules="required|email|max:255"
                required
                type="email"
                name="email"
                autocomplete="username" />
            <BaseInput
                v-model="model.password"
                :label="$t('auth.password')"
                rules="required"
                required
                :type="(showPassword) ? 'text' : 'password'"
                name="password"
                autocomplete="current-password">
                <template #inputIcon>
                    <div
                        class="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
                        @click="showPassword = !showPassword">
                        <Icon
                            :name="'heroicons:eye' + (showPassword ? '-slash' : '')"
                            class="h-5 w-5 text-gray-500" />
                    </div>
                </template>
            </BaseInput>

            <div class="flex flex-col sm:flex-row gap-4 justify-between items-center">
                <div>
                    <BaseCheckbox
                        v-model="model.remember"
                        name="stayLoggedIn">
                        {{ $t('auth.stayLoggedIn') }}
                    </BaseCheckbox>
                </div>
                <div class="text-sm">
                    <span
                        class="font-semibold text-primary-600 hover:text-primary-500 cursor-pointer"
                        @click.prevent="$emit('authPageVisible', 'AuthForgotPassword')">
                        {{ $t('auth.forgotPassword') }}
                    </span>
                </div>
            </div>

            <BaseButton
                class="w-full"
                color="primary"
                :disabled="loading || !!Object.keys(errors).length"
                type="submit">
                <span v-if="!loading">{{ $t('auth.login') }}</span>
                <span v-else>
                    <LoadingSpinner />
                    {{ $t('auth.pleaseWait') }}
                </span>
            </BaseButton>
        </div>
    </VForm>
</template>

<script setup>
import {useAuthStore} from '@/store/auth'
import {useNotification} from '@/composables/useNotification'
import {useSlideOverStore} from '@/store/slideOver'

const authStore = useAuthStore()
const notification = useNotification()
const slideOverStore = useSlideOverStore()
const {$apiRoute} = useNuxtApp()

defineEmits(['authPageVisible'])

const model = ref({
    email: null,
    password: null,
    remember: true
})

const loading = ref(false)
const showPassword = ref(false)

async function onSubmit() {
    loading.value = true

    await $lara.post('/app/spa/login', {
        body: {...model.value}
    }).then(async response => {
        await authStore.successfulLogin()
        slideOverStore.resetSlideOver()
        loading.value = false
    }).catch(async error => {
        loading.value = false

        notification.error(error.response._data?.message)
    })
}
</script>