<template>
    <VForm
        v-slot="{ errors }"
        @submit="onSubmit">
        <div class="flex flex-col gap-6">
            <!-- TODO organisation -->

            <BaseInput
                v-model="model.firstname"
                :label="$t('auth.firstname')"
                rules="required|max:255"
                required
                autocomplete="given-name"
                name="register-firstname" />
            <BaseInput
                v-model="model.lastname"
                :label="$t('auth.lastname')"
                rules="required|max:255"
                required
                autocomplete="family-name"
                name="register-lastname" />
            <BaseInput
                v-model="model.email"
                :label="$t('auth.email')"
                rules="required|email|max:255"
                required
                autocomplete="username"
                type="email"
                name="register-email" />
            <div>
                <BaseInput
                    v-model="model.password"
                    :label="$t('auth.password')"
                    type="password"
                    name="register-password"
                    rules="required|verify_password"
                    autocomplete="new-password"
                    required />
                <PasswordStrengthMeter
                    :model-value="model.password"
                    :strength-meter-only="true" />
            </div>

            <BaseInput
                v-model="model.password_confirmation"
                class="-mt-4"
                :label="$t('auth.repeatPassword')"
                :rules="{required: true, confirm_password: model.password}"
                required
                type="password"
                autocomplete="new-password"
                name="reset-password-confirmation" />

            <BaseButton
                class="w-full"
                color="primary"
                :disabled="loading || !!Object.keys(errors).length"
                type="submit">
                <span v-if="!loading">{{ $t('auth.register') }}</span>
                <span v-else>
                    <LoadingSpinner />
                    {{ $t('auth.pleaseWait') }}
                </span>
            </BaseButton>

            <div class="text-center">
                <small
                    class="underline-none cursor-pointer"
                    @click="$emit('authPageVisible', 'AuthLogin')">
                    {{ $t('auth.login') }}
                </small>
            </div>
        </div>
    </VForm>
</template>

<script setup>
import {useAuthStore} from '@/store/auth'
import AuthLogin from "@/components/pages/auth/AuthLogin.vue";

const authStore = useAuthStore()
const notification = useNotification()
const {$apiRoute} = useNuxtApp()

const model = ref({
    firstname: null,
    lastname: null,
    email: null,
    password: null,
    password_confirmation: null,
    remember: true
})

const loading = ref(false)

async function onSubmit(values, actions) {
    loading.value = true

    await $lara.post($apiRoute('spa.auth.register'), {
        body: {...model.value}
    }).then(async response => {
        loading.value = false
        await login()
        notification.success(t('users.welcome', {name: model.value.firstname}))
    }).catch(async error => {
        loading.value = false
        notification.error(error.response._data?.message)
        // actions.setErrors(error.response._data?.errors);
    })
}


async function login() {
    loading.value = true

    await $lara.post('/app/spa/login', {
        body: {...model.value}
    }).then(async response => {
        await authStore.successfulLogin('/', {celebrate: 1})
        loading.value = false
    }).catch(async error => {
        loading.value = false

        notification.error(error.response._data?.message)
    })
}

</script>