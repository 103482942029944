<template>
    <VForm
        v-slot="{ errors }"
        @submit="onSubmit">
        <div class="flex flex-col gap-6">
            <p class="text-neutral-500">
                {{ $t('auth.pleaseEnterNewPassword') }}
            </p>
            <div>
                <BaseInput
                    v-model="model.password"
                    :label="$t('auth.password')"
                    rules="required|verify_password"
                    required
                    type="password"
                    autocomplete="new-password"
                    name="reset-password" />
                <PasswordStrengthMeter
                    :model-value="model.password"
                    :strength-meter-only="true" />
            </div>
            <BaseInput
                v-model="model.password_confirmation"
                :label="$t('auth.repeatPassword')"
                :rules="{required: true, confirm_password: model.password}"
                required
                type="password"
                autocomplete="new-password"
                name="reset-password-confirmation" />
            <BaseButton
                color="primary"
                class="w-full mt-4"
                :disabled="loading || !!errors.length"
                type="submit">
                <span v-if="!loading">{{ $t('auth.savePassword') }}</span>
                <span v-else>
                    <LoadingSpinner />
                    {{ $t('auth.pleaseWait') }}</span>
            </BaseButton>
        </div>
    </VForm>
</template>


<script setup>
import {useAuthStore} from '@/store/auth'

const {t} = useI18n()

const loading = ref(false)
const model = ref({
    email: useRoute().query.email,
    password: '',
    password_confirmation: ''
})

onMounted(() => {
    if ((useAuthStore.isAuthenticated ?? null) !== null) navigateTo({path: '/'})
})

const emit = defineEmits(['authPageVisible']);

async function onSubmit() {
    loading.value = true;

    $lara.post(useNuxtApp().$apiRoute('password.update'), {
        body: {
            'token': useRoute().query.token,
            'email': useRoute().query.email,
            ...model.value
        }
    }).then(response => {
        useNotification().success(t('auth.resetPasswordSuccessTitle'), t('auth.resetPasswordSuccessText'))
        emit('authPageVisible', 'AuthLogin')
    }).catch(error => {
        useNotification().error(t('error'), error?.data?.message)
    }).finally(() => {
        loading.value = false;
    })
}

</script>