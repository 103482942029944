<template>
    <VForm
        v-slot="{ errors }"
        @submit="onSubmit">
        <div class="flex flex-col gap-6">
            <p class="text-neutral-500 dark:text-gray-300">
                {{ $t('auth.forgotPasswordText') }}
            </p>

            <BaseInput
                v-model="model.email"
                :label="$t('auth.email')"
                type="email"
                rules="required|email|max:255"
                required
                autocomplete="email"
                name="forgot-email" />

            <div class="flex justify-end">
                <span
                    :to="'/auth/register'"
                    class="font-bold text-decoration-none cursor-pointer"
                    @click.prevent="$emit('authPageVisible', 'AuthLogin')">{{ $t('auth.back') }}</span>
            </div>

            <BaseButton
                class="w-full"
                color="primary"
                :disabled="loading || !!errors.length"
                type="submit">
                <span v-if="!loading">{{ $t('auth.resetPassword') }}</span>
                <span v-else>
                    <LoadingSpinner />
                    {{ $t('auth.pleaseWait') }}
                </span>
            </BaseButton>
        </div>
    </VForm>
</template>

<script setup>
const loading = ref(false)
const model = ref({
    email: ''
})

const emit = defineEmits(['authPageVisible']);

const {t} = useI18n()

const notification = useNotification()

async function onSubmit() {
    loading.value = true
    const {error, pending} = await useAsyncData(() => $lara.post(useNuxtApp().$apiRoute('password.email'), {
        body: {...model.value}
    }))

    if (!error.value) {
        notification.success(t('auth.forgotPasswordSuccess'))
        emit('authPageVisible', 'AuthLogin')
    } else {
        notification.error(t('error'), error?.value?.data?.message)
    }
    loading.value = false
}
</script>